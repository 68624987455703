$(document).ready(initPage);

function initPage() {
  function updateScrollClass() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }

  updateScrollClass();

  $(window).scroll(function () {
    updateScrollClass();
  });

  $('.header .toggler').click(function(){
    $('body').toggleClass('is-expanded-menu');
  });

  $('.info-details .detail-item h3').click(function(){
    $(this).parents('.detail-item').toggleClass('is-expanded');
  })
}
